import { OnInit, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { ApiGatewayService } from '@drc-eca/eca-components-lib';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-short-token-redirect',
  templateUrl: './short-token-redirect.component.html',
  styleUrls: ['./short-token-redirect.component.scss']
})
export class ShortTokenRedirectComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiGatewayService: ApiGatewayService,
    public dialog: MatDialog
  ) {}

  getRoute(url: string) {
    const route = url.split('eca-portal-v2-ui/')[1];
    const n = route.lastIndexOf('/');
    return route.slice(0, n) + route.slice(n).replace('/', '');
  }

  async ngOnInit() {
    const { shortToken } = await this.route.params.pipe(take(1)).toPromise();
    const apiConfig = this.apiGatewayService.makeApiConfigString();
    const urlBase = `eca-security-service/${apiConfig}/v0/email/`;

    try {
      const response: any = await this.apiGatewayService.get(`${urlBase}shortUrl/${shortToken}`).toPromise();
      // Check url freshness for password resets
      if (response.redirectUrl.includes('reset-password')) {
        const freshnessResponse: any = await this.apiGatewayService.get(`${urlBase}checkShortUrlFresh/${shortToken}`).toPromise();
        if (freshnessResponse.shortUrlStatus !== 'fresh') {
          const urlBadText = 'This link has expired. You will not be able to update your password with this link. Please check your email inbox for a more recent reset-password link, or try clicking the reset password link again.';
          throw new HttpErrorResponse({status: 410, statusText: urlBadText});
        }
      }
      const targetRoute = this.getRoute(response.redirectUrl);

      await this.router.navigateByUrl(targetRoute);
    } catch (e) {
      const expiredText = 'This link has expired. Please click the reset password link again.';
      const errorPageText = e.status === 404 ? expiredText : e.statusText;
      history.pushState({}, '', location.origin + location.pathname + '#/forgot-password');
      await this.router.navigate([`/error/${e.status}`], { queryParams: { desc: errorPageText } });
    }
  }
}
